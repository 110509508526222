import {getCurrentInstance, reactive, toRefs, onActivated,onBeforeMount, onMounted, defineComponent, nextTick, computed} from 'vue';
import PdfJsEngineUtil ,{IPdfJsEngineDataObj} from "./pdfJsEngineUtil";
export default defineComponent ({
    name: "pdfJsEngine",
    props:{
        pdfJsEngineParams: {
            type: Object,
            default:()=>{return{}}
        },
    },
    title: "pdf预览",
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let pdfJsEngineParams=props.pdfJsEngineParams;
        let dataObj:IPdfJsEngineDataObj=reactive<IPdfJsEngineDataObj>({
            utilInst:{} as any,
            pdfJsEngineParams:{}
        })
        onActivated(()=>{
            nextTick(()=>{
                buildPdfUrl();
            })
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new PdfJsEngineUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(()=>{
                buildPdfUrl();
            })
        })
        const buildPdfUrl=()=>{
            dataObj.utilInst.initPdfJsEngineParams(pdfJsEngineParams);
            dataObj.pdfJsEngineParams.src +=dataObj.pdfJsEngineParams.source;
        }
        return{
            ...toRefs(dataObj)
        }
    }
});