import {ref, reactive, toRefs, computed, defineComponent,onActivated,onBeforeMount, onMounted, getCurrentInstance, nextTick} from 'vue';
import ViewFileUtil,{IViewFileDataObj} from './viewFileUtil';
import {useRoute} from "vue-router";
import PdfJsEngine from '@/components/base_comp/pdfEngine/pdfjs/PdfJsEngine.vue';
export default defineComponent ({
    name: 'clientViewFile',
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let route = useRoute();
        let dataObj:IViewFileDataObj=reactive<IViewFileDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            cxId:'',
            brandId:'',
            fileUrl:'',
            fileName:'',
            moduleType:0,
            pdfEngineParams:{
                source:'',
                showTitle:false,
                title:''
            }
        })
        onActivated(()=>{
            buildRoutParams();
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ViewFileUtil(proxy,dataObj);
            buildRoutParams();
        })
        onMounted(()=>{
        })
        const backHandler=async ()=>{
            switch (dataObj.moduleType+'') {
                // case "1": await proxy.$router.replace({path:'/clientUseManual'});break;//旧得，可以废弃了
                case "1": await proxy.$router.replace({path:'/clientUseManualList',query:{cxId:dataObj.cxId,brandId:dataObj.brandId}});break;
                case "2": await proxy.$router.replace({path:'/clientRepairManual'});break;
            }
        }
        //获取路由的参数
        const buildRoutParams=()=>{
            dataObj.cxId=route.query.cxId as string;//从pdf预览页面退回到使用手册页面得时候，要传入品牌id和车型id，所以这里先存起来
            dataObj.brandId=route.query.brandId as string;

            dataObj.fileUrl=route.query.fileUrl as string;
            dataObj.fileName=route.query.fileName as string;
            dataObj.moduleType= route.query.moduleType as unknown as number;
            dataObj.pdfEngineParams.source=route.query.fileUrl as string;
            dataObj.pdfEngineParams.title=route.query.fileName as string;
        }
        return{
            ...toRefs(dataObj),backHandler
        }
    },
    components: {PdfJsEngine}
});